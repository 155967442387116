/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  TestIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-bar-list.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/bar-list-state.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        id="bar-list"
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="modules/visualizations/bar-list"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="items" types={['VisualizationData[]']} isRequired>
            <Text>Data used to populate bar list buttons.</Text>
          </PropListItem>

          <PropListItem name="selectedLabels" types={['string[]']}>
            <Text>
              Array of labels corresponding to the currently selected items. The
              selected labels should match labels provided in the items prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onToggleItem" types={['(label: string) => void']}>
            <Text>
              Responds to bar list item press. The provided label string matches
              the label of the toggled item as provided in the items prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <TestIdPartial componentName="bar list" />
        </PropList>
      </Section>

      <Section title="Managing State">
        <Text className="uni-margin--half--bottom">
          To make managing the state of the selected items easier, we created{' '}
          <code>useToggleArray()</code>, which is a custom React hook that
          accepts an array of labels representing the initally selected items,
          and returns an array with two values:
        </Text>
        <List type="unordered" className="uni-margin--one--bottom">
          <li>
            <code>selectedLabels</code>: An array of currently selected labels
            to pass through the <code>selectedLabels</code> prop.
          </li>
          <li>
            <code>handleToggleItem</code>: A callback function to pass through{' '}
            <code>onToggleItem</code> to handle toggling the selected state of
            each item.
          </li>
        </List>
        <CodeSnippet
          id="state"
          disableCodeEditing
          code={stateSnippet}
          platform="react"
          gitHubLink="modules/visualizations/bar-list"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Modules / Bar List"
      />
    </PlatformTab>
  );
};

export default WebTab;
